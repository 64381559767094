
.table{
    width: 100%;
}
thead
{
  font-size: 24px;
  font-weight: bolder;
  height: 50px;
  position: sticky;
  top: 0;
  background-color:black  ;
  color: orange;
  margin: 50px;
}
.common
{
  text-align: center;
  font-size: 20px;
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.green{
    text-align: center;
  font-size: 20px;
  color: green;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.red{
    text-align: center;
  font-size: 20px;
  color: red;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.full{
    background-color: black;
}